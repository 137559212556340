/*
 * card-header
 *
 * Usage:
 * <ion-card-header class="card-header">
 *     <ion-card-title class="card-header__title">To-Do's</ion-card-title>
 * </ion-card-header>
 */

.card-header {
    // Primary
    background-color: var(--slateDark);
    padding: 0.5em 1.25em;
}

.card-header--secondary {
    // Secondary
    background-color: var(--tealDark);
}

.card-header--editable {
    padding: 0.125rem;
}

.card-header__title {
    color: var(--white);
    font-size: var(--text-size-large);
}

.card-header__toolbar {
    --background: unset;
    --min-height: 2em;

    .action-button {
        --background: unset;
        --background-focused: var(--blue);
        --box-shadow: unset;
        --color: var(--white);
        --color-focused: var(--white);

        margin: 0;
        height: 1.5em;
    }
}
