.popover__layout {
    --width: 37.5em;
    --max-width: 90%;
    --box-shadow: 0 5px 5px 0 rgba(0,0,0,0.5);
}

.alert {
    &__header {
        --background: var(--black);
        --color: var(--white);
        --padding-start: 0.625rem;
        --padding-end: 0.625rem;

        display: flex;
        align-items: center;
        height: 3em;
        font-size: var(--text-size-large);
    }

    &__text {
        padding: 0 0.375rem;
        line-height: 1.2;
        font-size: var(--text-size-large);
    }

    &__exit {
        --padding-start: 0;
        --padding-end: 0;

        text-decoration: none;
        color: var(--white);
        font-size: var(--text-size-normal);
    }

    &__list {
        background-color: var(--white);
    }

    &__item {
        --background: var(--white);
    }

    &__content {
        font-size: var(--text-size-normal);
    }

    &__button {
        --box-shadow: none;
        --background: var(--blue);

        font-size: var(--text-size-large);
        font-weight: var(--font-weight-semi-bold);
    }
}
