/*  Ionic Variables and Theming. For more info, please see:
 *  http://ionicframework.com/docs/theming/
 */

:root {
    /* http://ux.pearsonschool.com/design/colors/realize/ */
    --black: #000;
    --white: #fff;
    --grayDarkest: #222;
    --grayDarker: #333;
    --grayDark: #555;
    --gray: #7b7b7b;
    --grayLight: #cdcdcd;
    --grayLighter: #e2e2e2;
    --grayLightest: #f3f3f3;
    --grayWhite: #f9f9f9;
    --slateDarkest: #022c45;
    --slateDark: #004168;

    /* unofficial, per UX Invision mock */
    --slate: #4a7c9c;
    --slateLight: #e0e9ef;

    /* unofficial, per UX Invision mock */
    --slateLightest: #eef3f7;
    --blue: #026ecb;
    --blueLight: #0189ff;

    /* unofficial, per UX Invision mock */
    --tealDark: #09866d;

    /* unofficial, per UX Invision mock */
    --teal: #0ba888;
    --tealLight: #0dd4ae;

/* unofficial, per UX Invision mock */
    --tealLightest: #caede6;
    --greenDarkest: #304f09;
    --green: #008a00;
    --greenDark: #416b0c;
    --greenLighter: #8fbe00;
    --greenLight: #7fc624;
    --greenLightest: #edfaea;
    --greenMild: #d4f4ed;
    --yellow: #edc951;
    --brickDarker: #9f3115;
    --brickDark: #bc4122;
    --brick: #c75233;
    --brickLight: #ed5932;
    --brickLighter: #f58e72;
    --brickLightest: #ffe4d5;
    --redDarker: #6c010a;
    --redDark: #9e0b0f;
    --red: #cc333f;
    --redLight: #f3dede;

    /** Ionic CSS Variables **/

    /** primary **/
    --ion-color-primary: #3880ff;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #fff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;

    /** secondary **/
    --ion-color-secondary: #0cd1e8;
    --ion-color-secondary-rgb: 12, 209, 232;
    --ion-color-secondary-contrast: #fff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #0bb8cc;
    --ion-color-secondary-tint: #24d6ea;

    /** tertiary **/
    --ion-color-tertiary: #7044ff;
    --ion-color-tertiary-rgb: 112, 68, 255;
    --ion-color-tertiary-contrast: #fff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #633ce0;
    --ion-color-tertiary-tint: #7e57ff;

    /** success **/
    --ion-color-success: #10dc60;
    --ion-color-success-rgb: 16, 220, 96;
    --ion-color-success-contrast: #fff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #0ec254;
    --ion-color-success-tint: #28e070;

    /** warning **/
    --ion-color-warning: #ffce00;
    --ion-color-warning-rgb: 255, 206, 0;
    --ion-color-warning-contrast: #fff;
    --ion-color-warning-contrast-rgb: 255, 255, 255;
    --ion-color-warning-shade: #e0b500;
    --ion-color-warning-tint: #ffd31a;

    /** danger **/
    --ion-color-danger: #f04141;
    --ion-color-danger-rgb: 245, 61, 61;
    --ion-color-danger-contrast: #fff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d33939;
    --ion-color-danger-tint: #f25454;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 34, 34;
    --ion-color-dark-contrast: #fff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #fff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 244, 244;
    --ion-color-light-contrast: #000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
}
