/*
 * avatar
 *
 * Usage:
 * <ion-avatar class="avatar">
 *     <span class="avatar__initials">DJ</span>
 * </ion-avatar>
 */
.avatar {
    position: relative;
    background-color: var(--slate);
    font-size: var(--text-size-normal);
}

.avatar__initials {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: var(--white);
}

.inline-avatar {
    --background: unset;

    margin-left: 0;
    padding-left: 8px;

    .avatar {
        margin-right: 0.5em;
        font-size: var(--text-size-small);
    }

    .avatar__label {
        display: inherit;
    }
}

@include mq-up-to(x-small) {
    .inline-avatar {
        display: -webkit-box;
        // TODO: fix avatar layout to work with flex
        // display: flex;
    }
}
