/*

page-bar

Usage:
<ion-toolbar class="page-bar">
...

*/
.page-bar {
    --background: var(--grayWhite);
    --border-width: 0 0 1px 0;
    --border-style: solid;
    --border-color: var(--slateDark);
    /* stylelint-disable-next-line declaration-no-important */
    --padding-top: 1em !important;
    /* stylelint-disable-next-line declaration-no-important */
    --padding-end: 1.5em !important;
    /* stylelint-disable-next-line declaration-no-important */
    --padding-bottom: 1em !important;
    /* stylelint-disable-next-line declaration-no-important */
    --padding-start: 1.5em !important;

    .segment-button {
        --background-checked: var(--slateDark);
        --background-hover: var(--blue);
        --border-style: solid;
        --border-color: var(--slateDark);
        --border-width: 1px;
        --color: var(--slateDark);
        --indicator-color-checked: var(--slateDark);
        --padding-top: 0;
        --padding-end: 4.25em;
        --padding-bottom: 0;
        --padding-start: 4.25em;

        // Override Ionic - ion-segment-button in ion-toolbar has high specificity
        /* stylelint-disable-next-line declaration-no-important */
        max-width: 200px !important;
        min-height: 2em;

        &:hover {
            /* stylelint-disable-next-line declaration-no-important */
            color: var(--white) !important;
        }

        .segment-button__label {
            margin-top: 0.25em;
            margin-bottom: 0.125em;
            text-transform: none;
            font-size: var(--text-size-normal);
        }
    }

    .action-button {
        --background-focused: var(--blue);
        --color: var(--slateDark);
        --color-focused: var(--white);
    }
}

@include mq-up-to(x-small) {
    .page-bar {
        --padding-top: 0.75em;
        --padding-end: 0.75em;
        --padding-bottom: 0.75em;
        --padding-start: 0.75em;
    }
}
