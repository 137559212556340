/* Scout default responsive layout styles */

/** Only layout styles should be set in this file:
 ** Height and Width
 ** margin and padding
 ** borders
 ** display type
 ** box-sizing
 ** overflow
 ** flex, order, and direction
 ** top, bottom, left, right, and z-index

 ** No typography styles
 ** No fonts
 ** No colors
 ** No backgrounds
 ** No filters (box-shadow, glow, etc)
 */

/** z-index notes
 ** 1 - Footer and Menu bar
 ** 3 - Header
 ** 10 - Status Spinner
 */

/* Base application layout styles */
.scout {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

/* Layout Styles */
.flex-container-col,
.flex-container-row {
    display: flex;
    height: 100%;
    overflow: hidden;
}

.flex-container-col {
    flex-direction: column;
}

.flex-container-row {
    flex-direction: row;
}

.flex-middle {
    display: flex;
    flex: 1;
    min-height: 0; /* helps fix FF issue */
}

.flex-content-area {
    flex: 1;
    min-height: 0; /* helps fix FF issue */
}

.flex-side-nav {
    /* 100px is the fixed width of the columns */
    flex: 0 0 100px;
}

.flex-sidebar {
    flex: 0 0 350px;
    order: 0;
}

.rtl .flex-sidebar {
    order: 1;
}

.flex-item {
    -webkit-flex: auto;
    flex: auto;
    min-height: 0; /* helps fix FF issue */
}

/* bar specific styles */
.bar .bar-item {
    display: inline-block;
}

.header {
    z-index: 3;
}

.status-spinner {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 10;
}

.nav-bar {
    &.right,
    &.left {
        display: block;
        z-index: 3;
        width: 64px; /* UX: 62px */
    }
}

.footer {
    display: none;
    z-index: 1;
    width: 100%;
    height: 64px;

    &.nav-bar {
        width: 100%;
    }

    .list {
        margin: 0 auto;
        padding: 0;
        width: calc(100% - 10px);
    }

    .menu {
        margin: 0 10px;
    }
}

/* Menu styles */
.menu {
    margin: 10px 0;
    padding: 0;

    .list {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
    }

    .list-item {
        margin: 2px 2px 24px;
        border: 0 solid transparent;
        border-radius: var(--corner-radius-button);
        width: 58px;
        height: 48px;
    }
}

/* over-ride the default styles for nav buttons */
.menu .list-item .nav-item {
    display: block;
    border: none;
    height: 100%;

    .label-icon {
        padding: 3px;
        height: 100%;
    }

    .label-text {
        position: relative;
        bottom: 0;
        transform: translate(0,-100%);
    }
}

/* page styles */
.page-area {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

/* styles for items building from the left or right */
.from-left {
    margin: auto 0 auto 5px;
}

.from-right {
    margin: auto 5px auto 0;
}

/* header - Hide class select bar */
.bar-class-select {
    display: none;
}

@include mq-up-to(small) {
    /* header - Hide class selector  */
    .bar-header .class-select {
        display: none;
    }

    /* Header - Show class select bar */
    .bar-class-select {
        display: block;
    }
}

@include mq-up-to(x-small) {
    /* TODO: prevent drawer from overlapping header area */

    /* hide side nav bar */
    .nav-bar.left,
    .nav-bar.right {
        display: none;
    }

    /* show footer bar */
    .footer {
        display: block;
    }

    .menu {
        .list-item {
            margin: 2px;
        }
    }
}

/* Hide bottom nav bar and class select when in assessment-view/report-view */
@include mq-up-to(small) {
    .app-main.hide-footer-class-select {
        .bar-class-select,
        .footer {
            display: none;
        }
    }
}

.hide {
    display: none;
}
