/*

todo-chip

Usage:
<ion-chip class="todo-chip" outline color="dark">
    <div class="todo-chip-label">
        <div class="todo-chip-label__title"> ... </div>
        <div class="todo-chip-label__date">
            <span class="todo-chip-label__due"> ... </span>
            <span> ... </span>
        </div>
    </div>
</ion-chip>

*/
.todo-chip {
    margin-right: 0;
    border-radius: var(--corner-radius-button);
    border-color: var(--gray);
    background-color: var(--tealDark);
    padding: 0;
    height: 3.25em;
}

.todo-chip-label {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-width: 6em;
    height: 100%;
}

.todo-chip-label__title {
    color: var(--white);
}

.todo-chip-label__title,
.todo-chip-label__date {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
}

.todo-chip-label__date {
    background-color: var(--white);
    color: var(--grayDark);
    font-size: var(--text-size-small);
}

.todo-chip-label__due {
    margin-right: 0.25em;
}

.todo-chip--no-due-date {
    .todo-chip-label__title {
        height: 100%;
    }

    .todo-chip-label__date {
        display: none;
    }
}

@include mq-up-to(x-small) {
    .todo-chip {
        margin-right: 1em;
    }

    .todo-chip-label {
        min-width: 4em;
    }

    .todo-chip-label__due {
        display: none;
    }
}
