/* localization styles */
.rtl {
    direction: rtl;
}

.ltr {
    direction: ltr;
}

/* NO IE/Edge support [2019.02.22] */
.rtl-vert {
    -webkit-text-orientation: upright;
    text-orientation: upright;
    writing-mode: vertical-rl;
}

/* NO IE/Edge support [2019.02.22] */
.ltr-vert {
    text-orientation: upright;
    writing-mode: vertical-lr;
}

.upright-vert {
    text-orientation: upright;
}

/* squeeze each time part into a single vertical glyph spot */
time.vert {
    text-combine-upright: digits 4;
}

/* prevent squeezing into a single vertical glyph spot */
.no-tcy {
    text-combine-upright: none;
}
