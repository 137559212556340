/*  Typography variables and Theming. For more info, please see:
 *  http://ionicframework.com/docs/theming/
 */

:root {
    /*** Typography Variables ***/
    /** Scout fonts **/
    --font-text: cabin, sans-serif;
    --font-icon: 'k12_universal_icons';

    /* Ionic base font */
    --ion-font-family: var(--font-text);

    /* text sizes */
    --text-size-xxx-large:  1.875rem;   /* 1.875rem (30px) */
    --text-size-xx-large:   1.375rem;   /* 1.375rem (22px)*/
    --text-size-x-large:    1.25rem;    /* 1.25rem (20px) */
    --text-size-large:      1.125rem;   /* 1.125rem (18px) */
    --text-size-normal:     1rem;       /* 1rem (16px) */
    --text-size-small:      0.875rem;   /* 0.875rem (14px) */
    --text-size-x-small:    0.75rem;    /* 0.75rem (12px) */
    /* icon sizes */
    --icon-size-xxx-large:  4.5rem;     /* 4.5rem (72px) */
    --icon-size-xx-large:   3.125rem;   /* 3.125rem (50px) */
    --icon-size-x-large:    2.25rem;    /* 2.25rem (36px) */
    --icon-size-large:      1.875rem;   /* 1.875rem (30px) */
    --icon-size-normal:     1.5rem;     /* 1.5rem (24px) */
    --icon-size-small:      1.125rem;   /* 1.125rem (18px) */
    --icon-size-x-small:    1rem;       /* 1rem (16px) */

    /* Layout variables */
    --corner-radius-button: 2px;
    --corner-radius-input: 4px;
    --corner-radius-panel: 2px;
    --corner-radius-none: 0;
    --corner-radius-circle: 50%;
    --box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
    --font-weight-semi-bold: 600;

    /* Letter spacing variables */
    --letter-spacing-xxx-small: 0.03125em;   /* 0.03125em; (0.5px) */
}
