/*
Desktop First Media Queries

Large screen styles go in regular screen CSS.
As the screen gets smaller you override what you need to.

Note: Please update src/app/shared/constants/media-queries.ts if new breakpoint is added here.
*/
@mixin mq-up-to($breakpoint) {
    @if $breakpoint == "x-small" {
        @media only screen and (max-width: 414px) {
            @content;
        }
    }

    @else if $breakpoint == "small" {
        @media only screen and (max-width: 600px) {
            @content;
        }
    }

    @else if $breakpoint == "medium" {
        @media only screen and (max-width: 768px) {
            @content;
        }
    }

    @else if $breakpoint == "large" {
        @media only screen and (max-width: 992px) {
            @content;
        }
    }

    @else if $breakpoint == "x-large" {
        @media only screen and (max-width: 1200px) {
            @content;
        }
    }
}
