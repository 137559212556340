@import 'media-queries';

/*
    Layouts having Student-List and Student-Detail View
    Usage:
        <div class="flex-container-row" [ngClass]="{'student-selected': studentSelected }">
            <div class="student-list"></div>
            <div class="student-detail"></div>
        </div>
*/

.student-list {
    flex-shrink: 0;
    width: 320px;
}

.student-detail {
    width: 100%;
}

@include mq-up-to(large) {
    .student-list {
        width: 240px;
    }
}

@include mq-up-to(medium) {
    .student-list {
        width: 100%;
    }

    .student-detail {
        display: none;
    }

    &.student-selected {
        .student-list {
            display: none;
        }

        .student-detail {
            display: block;
        }
    }
}
