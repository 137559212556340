/**
 * Common Components
 */

/**
 * app-menu
 */
.app-menu {
    --width: 500px;
    --ion-background-color: var(--grayWhite);

    &.app-menu__media {
        --width: 100%;

        .media-menu {
            height: 100%;
        }
    }

    &.app-menu__dashboard-menu {
        .dashboard-menu {
            height: 100%;
        }
    }

    &.app-menu__notes {
        .notes-menu {
            height: 100%;
        }
    }

    &.app-menu__oral-chart {
        --width: 840px;

        .oral-chart {
            width: 100%;
            height: 100%;
        }
    }

    &.app-menu__search {
        --width: 540px;

        .search-menu {
            height: 100%;
        }
    }
}

.ion-slide {
    .assessment-card {
        display: none;
    }

    &.ion-slide--show {
        .assessment-card {
            display: block;
        }
    }
}

.menu-content {
    padding: 1em 0;
}

.error-modal {
    --height: 200px;
}

.service-worker-modal {
    --height: 140px;
    --max-width: 400px;
}

@include mq-up-to(large) {
    .app-menu.app-menu__oral-chart {
        --width: 100vw;
    }
}

@include mq-up-to(medium) {
    .app-menu.app-menu__observation-report {
        --width: 100%;
    }
}

@include mq-up-to(x-small) {
    .app-menu.app-menu__dashboard-menu,
    .app-menu.app-menu__notes,
    .app-menu.app-menu__search,
    .app-menu.app-menu__oral-chart {
        /* stylelint-disable-next-line declaration-no-important */
        --width: 100% !important;
    }
}

.confirm-comment-modal {
    --height: 160px;
}

@include mq-up-to(x-small) {
    .confirm-comment-modal {
        --width: calc(100% - 1rem);
    }
}

/**
 * media-modal
 */
.media-modal {
    --width: 70%;
}

@include mq-up-to(x-small) {
    .media-modal {
        --width: 100%;
    }
}

.invalid-assessment-modal {
    --height: 30%;
}

/**
 * menu-header
 */
.menu-header {
    padding: 0.5em 1.25em;
}

.menu-header__toolbar {
    --background: var(--slateDark);
    --min-height: 2em;

    .action-button {
        --background: unset;
        --background-focused: var(--blue);
        --box-shadow: none;
        --color: var(--white);
        --color-focused: var(--white);

        margin: 0;
    }
}

.menu-header__title {
    margin-left: 1rem;
    color: var(--white);
    font-size: var(--text-size-large);
    font-weight: bold;

    h2 {
        margin: 0;
        font-size: inherit;
        font-weight: inherit;
    }
}

.menu-header__close-button {
    color: var(--white);
}

/**
 * list-card
 *
 * Usage:
 * <ion-card class="list-card">
 *     ...
 *     <ion-card-content class="card-content">
 *         <ion-list class="list">
 *             <ion-item class="list-item
 *             ...
 *     </ion-card-content>
 * </ion-card>
 */
.list-card {
    border-radius: var(--corner-radius-panel);
    background: var(--white);
    min-height: 200px;

    .card-content,
    .list {
        padding: 0;
    }
}

.list {
    .list-item {
        --background: var(--grayWhite);
        --min-height: 3em;

        .checkbox,
        .todo-chip {
            display: flex;
            flex-shrink: 0;
            align-self: flex-start;
            margin-top: 1.125em;
            margin-bottom: 1.125em;
        }

        .list-item__label {
            align-self: flex-start;
        }
    }

    .list-item__title {
        white-space: normal;
        font-size: var(--text-size-normal);
        font-weight: bold;
    }

    // this could be a general utility or use `.ion-text-nowrap`
    .list-item__title--ellipsis {
        display: block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .list-item__subtitle {
        white-space: normal;
        color: var(--grayDark);
        font-size: var(--text-size-normal);
    }

    .list-item__subtitle-text {
        display: inline-block;
        width: 100%;
    }

    .list-item__info {
        margin: 0.625em 0 0;
        padding-top: 0;
        color: var(--grayDark);
        font-size: var(--text-size-normal);
    }

    .list-item__icon {
        margin-right: 0.5em;
        font-size: var(--icon-size-normal);
    }

    .list-item--zero-state {
        text-align: center;

        .zero-state {
            padding: 2em 0;
        }

        .zero-state__title {
            margin-bottom: 1rem;
            color: var(--slateDark);
            font-size: var(--text-size-large);
            font-weight: bold;
        }

        .zero-state__details {
            margin-bottom: 1rem;
            white-space: normal;
            font-size: var(--text-size-normal);
        }

        .action-button {
            --background: var(--tealDark);
            --background-focused: var(--blue);
            --box-shadow: 0;
            --color: var(--white);
            --color-focused: var(--white);

            font-size: var(--text-size-x-large);
        }
    }

    .list-item--assessment-item {
        color: var(--tealDark);
    }

    .list-item--note-item,
    .list-item--media-item,
    .list-item--todo-item {
        color: var(--slateDark);
    }

    .chip {
        border-radius: var(--corner-radius-button);
        border-color: var(--gray);
        background-color: var(--grayWhite);
    }

    .chip__label {
        color: var(--grayDark);
    }

    .checkbox {
        --background: var(--white);
        --background-checked: var(--black);
        --border-color-checked: var(--black);

        /* stylelint-disable-next-line declaration-no-important */
        margin-right: 1em !important;
    }
}

@include mq-up-to(x-small) {
    .list {
        .list-item {
            --padding-start: 0.75em;
            --padding-end: 0;
        }
    }
}

/**
 * content-expandable
 */
.content-expandable__button {
    display: none;
}

@include mq-up-to(x-small) {
    .content-expandable--collapsed {
        display: none;
    }

    .content-expandable__button {
        display: inline-block;
    }

    .content-expandable__button--expanded {
        display: none;
    }
}

/* action-button
 * Usage:
 * <ion-button class="action-button">
 *     <div slot="start" class="action-button__icon label-icon icon-addition" aria-hidden="true"></div>
 *     <ion-label class="action-button__label">Add Note</ion-label>
 * </ion-button>
 */
.action-button--link {
    --background: unset;
    --box-shadow: unset;
    --color: var(--blue);
}

.action-button__icon {
    margin-right: 0.25em;
}

.action-button__label {
    text-transform: none;
    font-size: var(--text-size-large);
}

/**
 * textarea
 */
.textarea {
    --background: var(--white);
    // curse you "ion-item.sc-ion-textarea-md-h:not(.item-label), ion-item:not(.item-label) .sc-ion-textarea-md-h"
    /* stylelint-disable-next-line declaration-no-important */
    --padding-start: 0.75em !important;
    --padding-end: 0.75em;

    border: 1px solid var(--grayLight);
    border-radius: var(--corner-radius-input);
}

/**
 * form-group
 */
.form-group {
    border-bottom: 1px solid var(--grayLight);
    padding-bottom: 1em;

    &:last-child {
        border-bottom: none;
    }
}

/**
 * date-picker
 * <ion-datetime class="date-picker" ... >
 */
.date-picker {
    --placeholder-color: var(--blue);

    // Date display color: div.datetime-text
    color: var(--blue);
}

.picker-opt {
    overflow: visible;
}

.picker-opts-right {
    justify-content: flex-end;
}

/**
 * toggle-switch
 * <ion-toggle class="toggle-switch" ... >
 */
.toggle-switch {
    --background: var(--gray);
}

/**
 * alert
 */
ion-alert {
    .alert-button-inner {
        color: var(--blue);
    }
}

/**
 * popover
 */
.user-menu__popover {
    .popover-content {
        /* stylelint-disable-next-line declaration-no-important */
        top: 2.8rem !important;
        right: 0;
        /* stylelint-disable-next-line declaration-no-important */
        left: unset !important;
        border-radius: var(--corner-radius-none);
    }

    .popover-arrow {
        display: none;
    }
}

@include mq-up-to(small) {
    .user-menu__popover {
        .popover-content {
            /* stylelint-disable-next-line declaration-no-important */
            top: 2.43rem !important;
        }
    }
}

@include mq-up-to(x-small) {
    .user-menu__popover {
        .popover-content {
            // from .popover-content.sc-ion-popover-md
            /* stylelint-disable-next-line declaration-no-important */
            top: 2.5rem !important;
            right: 10px;
            border-radius: var(--corner-radius-panel);
        }
    }
}

.zero-state-container {
    margin: 2.5em 0;
    text-align: center;

    .zero-state-label {
        color: var(--slateDark);
        font-size: var(--text-size-large);
        font-weight: var(--font-weight-semi-bold);
    }
}
