/* Scout default Theme specific styles */

/** Only Theme styles should be set in this file:
 ** Typography styles
 ** Fonts
 ** Colors
 ** Backgrounds
 ** Filters
 */

:root {
    --ion-background-color: var(--slateLightest);
    --shadow-color: rgba(0,0,0,0.4);
}

.header {
    box-shadow: 0 2px 4px -1px var(--shadow-color);
    background: var(--black);
    color: #d6d6d6;
}

.nav-bar {
    background-color: var(--white);

    &.left {
        box-shadow: 2px 0 4px -1px var(--shadow-color);
    }

    &.right {
        box-shadow: -2px 0 4px -1px var(--shadow-color);
    }
}

.footer {
    box-shadow: 0 -2px 4px -1px var(--shadow-color);
    background-color: var(--white);

    &.nav-bar {
        /* nav bar styles here */
    }
}

.nav {
    .list-item {
        list-style-type: none;
        text-align: center;
    }
}

/* over-ride the default styles for buttons */
.nav .list-item .nav-item {
    cursor: pointer;
    text-align: inherit;
    text-decoration: none;
    color: var(--grayDark);
    font-size: inherit;
    user-select: none;

    &.selected {
        color: var(--blue);
    }

    .label-icon {
        font-size: var(--icon-size-normal);
    }

    .label-text {
        vertical-align: bottom;
        line-height: 1.5;
        font-size: var(--text-size-x-small);
    }
}
