/* general utility classes */
.center {
    margin: auto;
}

.center-children {
    position: relative;
}

.flex-middle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.middle {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.center-text {
    vertical-align: middle;
    text-align: center;
}

.hidden {
    display: none;
}

.invisible {
    visibility: hidden;
}

.screen-reader-only {
    position: absolute;
    top: 0;
    left: -1000px;
    border: none;
    background: none;
    width: 0;
    height: 0;
    overflow: hidden;
}

.no-bullets {
    list-style: none;
}

/*** Space evenly rules ***/
.horizontal .space-evenly {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}

.list-item {
    display: block;
    flex: 0 1 auto;
}

/*** Custom Skeleton Styles ***/
.custom-skeleton ion-skeleton-text {
    line-height: 1;
}

.custom-skeleton ion-skeleton-text.inline {
    display: inline-block;
}

.custom-skeleton ion-skeleton-text.center {
    margin-right: auto;
    margin-left: auto;
}

.custom-skeleton .assessment-card__progress {
    align-self: center;
    margin: 1rem auto;
    border: 0.875rem solid var(--grayLighter);
    border-radius: var(--corner-radius-circle);
    width: 102px;
    height: 102px;
}

.custom-skeleton ion-icon {
    opacity: 0.2;
}
